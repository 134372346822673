import home from "./home";
import clients from "./clients";
import accSettings from "./accSettings";
import help from "./help";
import moderation from "./moderation";
// import navigation from "./navigation";
// import homeN from "./home_N";
// import subjectTab from "./subjectTab";
// import calendarTab from "./calendarTab";
// import formsAndReportsTab from "./formsAndReportsTab";
// import profileTab from "./profileTab";
// import usersTab from "./usersTab";

export default [
	...home,
	...clients,
	...accSettings,
	...help,
	{
		title: "Guide",
		route: 'guide',
		icon: "BookOpenIcon",
		action: "general",
		resource: "general",
	},
	...moderation,
	// ...navigation,
	// ...homeN,
	// ...subjectTab,
	// ...calendarTab,
	// ...formsAndReportsTab,
	// ...profileTab,
	// ...usersTab,
];

export default [
	/* {
		header: "Help",
		action: "general",
		resource: "general",
	}, */
	{
		title: "Help",
		route: "diko-faq",
		icon: "HelpCircleIcon",
		action: "general",
		resource: "general",
	},
	/* {
		title: "Blog",
		route: "pages-blog-list",
		icon: "LayoutIcon",
		action: "general",
		resource: "general",
	}, */
	/* {
		title: "Ar-Ge",
		route: "ar-ge",
		icon: "InfoIcon",
		action: "general",
		resource: "general",
	}, */
];

export default [
	{
		header: "Moderation",
		action: "general",
		resource: "admin",
	},
	{
		title: "Approval",
		route: "approval-list",
		icon: "CheckSquareIcon",
		action: "read",
		resource: "A.Approval",
	},
	{
		title: "Prices",
		route: "admin-price-list",
		icon: "DollarSignIcon",
		action: "read",
		resource: "A.Package",
	},
	{
		title: "Discounts",
		route: "admin-discount-list",
		icon: "PercentIcon",
		action: "read",
		resource: "A.Discount",
	},
	{
		title:"Satın Alımlar",
		route:"all-payments",
		icon:"ShoppingCartIcon",
		action:"read",
		resource:"A.Payment",
	},
	{
		title:"Üye Hesapları",
		route:"users-list",
		icon:"UsersIcon",
		action:"read",
		resource:"A.User",
	},
];

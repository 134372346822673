export default [
	/* {
		header: "Diko Panel",
		action: "general",
		resource: "general",
	}, */
	{
		title: "Home",
		route: "home",
		icon: "HomeIcon",
		action: "general",
		resource: "general",
	},
];

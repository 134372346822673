<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>
    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <dark-Toggler class="d-none d-lg-block" />
    </div>
    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown right toggle-class="d-flex align-items-center dropdown-user-link" class="dropdown-user">
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">{{ userName }}</p>
            <!-- <span class="user-status">Premium Kullanıcı</span> -->
          </div>
          <b-avatar size="50" variant="" badge :src="userAvatar" class="badge-minimal" badge-variant="success" />
          <!-- require('@/assets/images/avatars/14-small.png') -->
        </template>
        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon size="16" icon="HomeIcon" class="mr-50" />
          <router-link to="/"><span>Anasayfa</span></router-link>
        </b-dropdown-item>
        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon size="16" icon="SettingsIcon" class="mr-50" />
          <router-link to="/account-settings"><span>Hesap Ayarları</span> </router-link>
        </b-dropdown-item>
        <b-dropdown-divider />
        <b-dropdown-item link-class="d-flex align-items-center">
          <dark-Toggler />
        </b-dropdown-item>
        <b-dropdown-item @click="logoutFunction" link-class="d-flex align-items-center">
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>Güvenli Çıkış</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from "bootstrap-vue";
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";
import store from "@/store";
import useJwt from "@/auth/jwt/useJwt";
import { initialAbility } from "@/libs/acl/config";

export default {
  data() {
    return {
      userName: "",
      userAvatar: "",
    };
  },
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,

    // Navbar Components
    DarkToggler,
  },
  created() {
    console.log(localStorage.getItem("userName"));
    this.userName = localStorage.getItem("userName");
    this.userAvatar = localStorage.getItem("userAvatar");
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
    logoutFunction() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);

      // Remove userData from localStorage
      localStorage.removeItem("userData");

      // Reset ability
      this.$ability.update(initialAbility);

      // Redirect to login page
      this.$router.push({ name: "auth-login" });
    },
  },
  computed: {
    getUserName() {
      console.log("store denem" + store.state.userInfo.name);
      return store.state.userInfo.name;
    },
  },
};
</script>

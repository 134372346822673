export default [
  /* {
    header: "Clients Operations",
    action: "general",
    resource: "general",
  }, */

  {
    title: "Clients",
    route: "clientlist",
    icon: "UsersIcon",
    action: "general",
    resource: "general",
  },
  /* {
    title: "Calendar",
    route: "calendar",
    icon: "CalendarIcon",
    action: "general",
    resource: "general",
  }, */
  /* {
		title: "Tests",
		route: "tests",
		icon: "ZapIcon",
		action: "general",
		resource: "general",
	},
	{
		title: "Enter Exercises",
		route: "enter-exercise",
		icon: "DribbbleIcon",
		action: "general",
		resource: "general",
	}, */
];

export default [
	/* {
		header: "Account",
		action: "general",
		resource: "general",
	}, */
	/* {
		title: "My Subscription",
		route: "account-settings-plans",
		icon: "CreditCardIcon",
		action: "general",
		resource: "general",
	}, */
	{
		title: "Account Settings",
		route: "account-settings",
		icon: "SettingsIcon",
		action: "general",
		resource: "general",
	},
	{
		title: "My Subscription",
		route: "financial-settings",
		icon: "BarChart2Icon",
		action: "create",
		resource: "S.Exercise",
	},
	{
		title:"Satın Aldıklarım",
		route:"my-payments",
		icon:"ShoppingCartIcon",
		action:"create",
		resource:"S.Exercise",
	},
	/* {
		title: "Reports",
		route: "reports",
		icon: "FileTextIcon",
		action: "general",
		resource: "general",
	}, */
	/* {
		title: "Perm Check",
		route: "acl",
		icon: "LockIcon",
		action: "general",
		resource: "general",
	}, */
	{
		title: "Forms",
		route: "forms",
		icon: "FileIcon",
		action: "general",
		resource: "general",
	},
];
